import React from 'react'
import map from '../../Assets/map.webp'

const Work = () => {
    return (
        <div className='mt-12'>
            <div className="flex justify-center">
                <img src={map} alt="" />
            </div>
        </div >
    );
};

export default Work
